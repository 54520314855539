import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  Button,
  Confirm,
  Form,
  Header,
  Loader,
  Segment,
  Dropdown,
  Breadcrumb,
  Select,
  Card,
  Modal,
} from 'semantic-ui-react';
import { FieldGroup, ImageUpload, TransitionGroup } from 'components';
import { ProjectModel, Project, Dealer, DealerPartner } from 'models';
import { ProjectService } from '.';
import { useAuth, useFeatureFlags, useUI } from 'services';
import { USER_MSG } from 'strings';
import { Orders } from '../orders';
import ProjectMeasurement from './ProjectMeasurement';
import fileDownload from 'js-file-download';
import ProjectTable from './ProjectTable';
import { MountedStyle, UnmountedStyle } from 'services/UI';
import OrderProvider from '../../context/OrderContext';
import {
  catchAndThrowErrors,
  checkFeatureAuthorization,
  Features,
  sanitizeFilename,
} from '../../utils';
import { CompanyService } from 'sections/employee';
import { APIEntity } from 'services/APIService';
import { dealerDirectString } from 'components/orders/Forms/Partials/Product/ProductFormConstants';
import {
  TYPE_DIRECT,
  TYPE_BUYING_GROUP,
  TYPE_DISTRIBUTOR,
  PurchasePartner,
} from 'models/Dealer';
import {
  BrandsResponse,
  SubDealerResponse,
} from 'sections/company/CompanyService';
import './ProjectDetail.scss';
import { Role } from 'models/Role';

export const NEW_PROJECT_ID_STRING = 'new';

export default function ProjectDetail(): React.ReactElement {
  const {
    // @ts-ignore
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const { user } = useAuth();
  const { showError, showSuccess } = useUI();
  const isNew = id === NEW_PROJECT_ID_STRING;
  const flags = useFeatureFlags();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(isNew);
  const [saveDealer, setSaveDealer] = useState(false);
  const [dealerId, setDealerId] = useState<string | undefined>();
  const [dealers, setDealers] = useState([] as Dealer[]);
  const [brands, setBrands] = useState<BrandsResponse[]>();

  const [allSubDealers, setAllSubDealers] = useState([] as SubDealerResponse[]);
  const [customerNumber, setCustomerNumber] = useState<string>();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [updateSubDealer, setUpdateSubDealer] = useState(false);

  const [showSubDealersLoading, setShowSubDealersLoading] = useState(false);
  const [generatingDxf, setGeneratingDxf] = useState(false);

  const [purchasePartners, setPurchasePartners] = useState<PurchasePartner[]>();
  const [purchasePartnersErr, setPurchasePartnersErr] = useState(false);
  const [isUserDealer, setIsUserDealer] = useState(false);
  const [isDistributorDealer, setIsDistributorDealer] = useState(false);
  const [shouldExtend, setShouldExtend] = useState(!isNew);
  const hasProjectAccess = user?.role !== Role.DESIGN;
  const hasReassignAccess =
    user?.role === Role.LATHAM_ADMIN ||
    user?.role === Role.BAI_ADMIN ||
    user?.role === Role.CUST_SERV ||
    user?.role === Role.SALES;
  const hasDownloadProjectAccess =
    user?.role === Role.BAI_ADMIN || user?.role === Role.LATHAM_ADMIN;

  const None = 'None';
  const defaultSubDealer: SubDealerResponse = {
    customerName: None,
    customerBranch: '',
    customerCompany: '',
    customerNumber: '0',
  };
  const hasSubDealer = allSubDealers.length > 0;
  const [form, setForm] = useState({
    loading: false,
    confirm: null as {
      title: string;
      msg: any;
      action: any;
      ok?: string;
    } | null,
  });
  const [project, setProject] = useState(
    ProjectModel({
      userId: user?.id,
    })
  );
  const [projId, setProjId] = useState('');
  const onSuccess = useCallback(() => {
    setEditing(false);
    setForm({ loading: false, confirm: null });
    showSuccess();
  }, [setEditing, setForm, showSuccess]);

  const onError = useCallback(() => {
    setForm({ loading: false, confirm: null });
    showError();
  }, [setForm, showError]);

  const onSubmit = useCallback(
    (projData?: Project) => {
      if (!projData && !project.isValid()) {
        showError(USER_MSG.ERROR_INVALID);
        return;
      }
      // trim data to transfer
      const data = projData ? projData : { ...project.data };
      delete data.resources;
      delete data.scans;
      delete data.orders;
      delete data.dealerName;

      setForm({ loading: true, confirm: null });
      if (isNew) {
        ProjectService.add(data)
          .then((proj) => {
            setProjId(proj.id);
            onSuccess();
            setProject(ProjectModel(proj));
            history.push(`/projects/${proj.id}`);
          })
          .catch((error) => {
            setForm({ loading: false, confirm: null });
            catchAndThrowErrors(
              error,
              showError,
              USER_MSG.ERROR_CREATING_PROJECT
            );
          });
      } else {
        ProjectService.get(id, true).then(async (proj) => {
          const projectNotes = proj.notes?.trim() || '';
          const updatedNotes = data?.notes?.trim() || '';
          const notesUpdated = projectNotes !== updatedNotes;
          await ProjectService.set(data, 'PATCH', false)
            .then((proj) => {
              onSuccess();
              setProject(ProjectModel({ ...project.data, ...proj }));
            })
            .catch((error) => {
              setForm({ loading: false, confirm: null });
              catchAndThrowErrors(
                error,
                showError,
                USER_MSG.ERROR_CREATING_PROJECT
              );
            });
          if (notesUpdated) {
            rerunDXF(proj);
          }
        });
      }
    },
    [isNew, project, showError, onSuccess, onError]
  );

  const navigateToProjectList = () => {
    setProject(ProjectModel({} as Project));
    history.push(`/`);
  };

  const deleteProject = useCallback(() => {
    setForm({ loading: true, confirm: null });
    ProjectService.del(id)
      .then(() => {
        navigateToProjectList();
        onSuccess();
      })
      .catch((error) => {
        setForm({ loading: false, confirm: null });
        catchAndThrowErrors(error, showError, USER_MSG.ERROR_DELETING_PROJECT);
      });
  }, [id, history, onSuccess, onError]);

  const downloadProject = useCallback(async () => {
    try {
      const lathamOrderSchema = project.data.orders
        ? await ProjectService.getOrdersLathamSchema(project.data.id)
        : {};
      fileDownload(
        JSON.stringify({ ...project.data, lathamOrderSchema }),
        sanitizeFilename(project.data.name) + '.json'
      );
    } catch (e) {
      showError();
    }
  }, [project.data]);

  const cancelEdit = useCallback(
    (e) => {
      // overwrite changed fields
      ProjectService.get(id, true).then((proj) => {
        setProject(ProjectModel({ ...project.data, ...proj }));
      });
      e.preventDefault();
      isNew ? navigateToProjectList() : setEditing(false);
    },
    [isNew]
  );

  const CONFIRM = {
    DELETE: {
      title: USER_MSG.CONFIRM_DELETE.title.replace('{name}', project.data.name),
      msg: USER_MSG.CONFIRM_DELETE.msg,
      action: deleteProject,
      ok: 'DELETE',
    },
  };

  const fetchProject = useCallback(async () => {
    try {
      setLoading(true);
      ProjectService.get(id, true).then((proj) => {
        setProject(ProjectModel({ ...project.data, ...proj }));
      });
      fetchDealersAndBrands();
    } catch (error) {
      showError();
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDealersAndBrands = useCallback(async () => {
    try {
      setLoading(true);
      const dealers = await CompanyService.getDealers();
      setDealers(dealers as Dealer[]);
      if (checkFeatureAuthorization(Features.GetAllBrands, user?.role)) {
        const brands = await CompanyService.getAllBrands();
        setBrands(brands);
      }
    } catch (error) {
      showError();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (shouldExtend) {
      fetchProject();
    } else {
      fetchDealersAndBrands();
    }
  }, [shouldExtend, fetchProject]);

  useEffect(() => {
    setDealerId(project.data.dealerId);
    if (project.data.customerNumber && project.data.customerNumber.length > 0) {
      setCustomerNumber(project.data.customerNumber.toString());
    }
  }, [project]);

  const saveSubDealer = async () => {
    if (allSubDealers.length > 0) {
      if (dealerId && customerNumber != project.data.customerNumber) {
        const data: Project = {
          ...project.data,
          dealerId: dealerId,
          customerNumber:
            customerNumber && customerNumber != '0' ? customerNumber : '',
        };
        if (customerNumber == '0') {
          setCustomerNumber('');
        }
        onSubmit(data);
      }
    }
    setUpdateSubDealer(false);
  };

  const updateDealer = async () => {
    if (dealerId && dealerId !== project.data.dealerId) {
      const data: Project = {
        ...project.data,
        dealerId: dealerId,
        customerNumber: '',
      };

      if (!project.isValid()) {
        showError(USER_MSG.ERROR_INCOMPLETE_PROJECT);
        return;
      }
      onSubmit(data);
    }
    setSaveDealer(false);
  };

  const cancelUpdateDealer = () => {
    setDealerId(project.data.dealerId);
    setSaveDealer(false);
  };

  const cancelUpdateSubDealer = () => {
    setCustomerNumber(project.data.customerNumber);
    setUpdateSubDealer(false);
  };

  const subDealersMappings = useCallback(
    () =>
      allSubDealers
        .map((dealer: SubDealerResponse) => {
          return {
            key: dealer.customerNumber,
            value: dealer.customerNumber.toString(),
            text:
              dealer.customerName != None
                ? `${dealer.customerName} (${dealer.customerNumber}- ${dealer.customerBranch})`
                : `${dealer.customerName}`,
          };
        })
        .sort((a, b) => {
          if (a.text == None) return -1;
          if (b.text == None) return 1;
          return a.text
            .toLocaleLowerCase()
            .localeCompare(b.text.toLocaleLowerCase());
        }),
    [allSubDealers]
  );

  useEffect(() => {
    if (dealers && dealers.length > 0) {
      const dealer = dealers.find(
        (dealer) => dealer.id === project.data.dealerId
      );
      setIsDistributorDealer(
        Boolean(
          dealer ? dealer.isDistributorDealer && flags.dealerDistributor : false
        )
      );
    }
  }, [dealers, project.data.dealerId]);

  const companyMappings = useCallback(
    () =>
      dealers
        .map((dealer: Dealer) => {
          const dealerBrand = brands
            ? brands.filter((el) => el.dealerId === dealer.id)[0]
            : null;
          let str = '';

          if (dealerBrand?.direct && dealerBrand?.direct.code !== '0') {
            str += `Direct(${dealerBrand.direct.code}-${dealerBrand.direct.branchCode})`;
          }

          if (
            dealerBrand?.throughBuyingGroup &&
            dealerBrand?.throughBuyingGroup.code !== '0'
          ) {
            if (str !== '') {
              str += ', ';
            }
            str += `${dealerBrand.throughBuyingGroup.name}(${dealerBrand.throughBuyingGroup.code}-${dealerBrand.throughBuyingGroup.branchCode})`;
          }

          if (
            dealerBrand?.throughDistribution &&
            dealerBrand?.throughDistribution.distributorCustomerAccount
          ) {
            dealerBrand?.throughDistribution.distributorCustomerAccount.forEach(
              (el) => {
                if (el?.code !== '0') {
                  if (str !== '') {
                    str += ', ';
                  }
                  str += `${el.name}(${el.code}-${el.branchCode})`;
                }
              }
            );
          }

          return {
            key: dealer.id,
            value: dealer.id,
            text: `${dealer.name} (${dealer.rewardsId}): ${str}`,
            isUserDealer: dealer.isUserDealer,
          };
        })
        .filter((el) => !el.isUserDealer || el.key === project.data.dealerId)
        .sort((a, b) =>
          a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase())
        ),
    [dealers, dealerId, project, brands]
  );

  const fetchBrandsInfo = async (
    dealerInfo: APIEntity<Dealer> | DealerPartner
  ) => {
    try {
      const purchasePartners = await CompanyService.getBrandsById(
        dealerInfo.rewardsId
      );

      const { direct, throughBuyingGroup, throughDistribution } =
        purchasePartners;
      const formattedPartners = direct
        ? [{ ...direct, name: dealerDirectString, type: TYPE_DIRECT }]
        : [];
      if (throughBuyingGroup?.name) {
        formattedPartners.push({
          ...throughBuyingGroup,
          type: TYPE_BUYING_GROUP,
        });
      }
      if (throughDistribution?.distributorCustomerAccount?.length) {
        throughDistribution.distributorCustomerAccount.forEach(
          (distributor) => {
            formattedPartners.push({ ...distributor, type: TYPE_DISTRIBUTOR });
          }
        );
      }

      setPurchasePartners(
        // Distributors without brands should not be shown
        formattedPartners.filter((partner) => partner.brands.length > 0)
      );
      setPurchasePartnersErr(false);
    } catch (error: any) {
      setPurchasePartnersErr(true);
    }
  };

  useEffect(() => {
    if (dealers.length < 0) {
      return;
    }
    const dealer = dealers.find((val) => val.id === dealerId);
    setIsUserDealer(dealer?.isUserDealer ?? false);
    if (dealer && dealer.isDistributorDealer) {
      setShowSubDealersLoading(true);
      fetchSubDealers(dealer.id);
    }
    if (
      dealer &&
      saveDealer &&
      dealer.isDistributorDealer &&
      flags.dealerDistributor
    ) {
      fetchBrandsInfo(dealer);
    }
  }, [dealerId, dealers]);

  const fetchSubDealers = useCallback(async (dealerId: string) => {
    try {
      const subDealers = await CompanyService.getSubDealer(dealerId);
      if (subDealers.length > 0) {
        setAllSubDealers([...subDealers, defaultSubDealer]);
      }
      setShowSubDealersLoading(false);
    } catch (error: any) {
      setShowSubDealersLoading(false);
      setAllSubDealers([]);
    }
  }, []);

  useEffect(() => {
    if (allSubDealers.length > 0) {
      if (!project.data.customerNumber) {
        setCustomerNumber('');
        setDisableSaveBtn(true);
        setUpdateSubDealer(true);
      } else {
        setCustomerNumber(project.data.customerNumber);
      }
    } else {
      setDisableSaveBtn(false);
    }
  }, [allSubDealers]);

  useEffect(() => {
    if (customerNumber === project.data.customerNumber) {
      setDisableSaveBtn(true);
    } else {
      setDisableSaveBtn(false);
    }
  }, [customerNumber]);

  const rerunDXF = async (proj: Project) => {
    try {
      if (
        proj.poolDetails &&
        proj.poolDetails.poolShape &&
        proj.resources &&
        proj.resources?.length > 0
      ) {
        setGeneratingDxf(true);
        await ProjectService.generateDXF(proj.id)
          .then(async () => {
            await fetchProject();
            setGeneratingDxf(false);
          })
          .catch(async (error) => {
            setGeneratingDxf(false);
            showError({ title: USER_MSG.ERROR_DXF.title, msg: error.message });
          })
          .finally(() => {
            setGeneratingDxf(false);
          });
      }
    } catch (error) {
      setGeneratingDxf(false);
      showError();
    }
  };

  return loading ? (
    <TransitionGroup transition="zoom" isVisible={!loading}>
      <Loader active size="massive" content="Loading" />
    </TransitionGroup>
  ) : !isNew && !project.data?.id ? (
    <h2>Project not found</h2>
  ) : (
    <>
      <TransitionGroup transition="scale" isVisible={!loading}>
        <Segment className="pad">
          <Form loading={form.loading}>
            <Header size="large">
              <Breadcrumb>
                <Breadcrumb.Section link onClick={navigateToProjectList}>
                  Projects
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section>
                  {isNew ? 'New Project' : project.get('name')}
                </Breadcrumb.Section>
              </Breadcrumb>
              {!editing && (
                <Dropdown
                  simple
                  direction="left"
                  size="large"
                  icon="cog"
                  style={{ float: 'right' }}
                >
                  <Dropdown.Menu>
                    {hasProjectAccess && (
                      <Dropdown.Item
                        icon="pencil"
                        text="Edit Project"
                        onClick={() => setEditing(true)}
                      />
                    )}
                    {hasDownloadProjectAccess && (
                      <Dropdown.Item
                        icon="cloud download"
                        text="Download Project"
                        onClick={downloadProject}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Header>

            <ImageUpload
              project={project.data}
              projId={projId}
              setProjId={setProjId}
              className="rel"
              onError={onError}
              inEditMode={editing}
              actualProjectId={id}
            />

            {hasReassignAccess && (
              <>
                <div className="field">
                  <label>Dealer</label>
                  <Select
                    search
                    className="dealer-dropdown"
                    placeholder={
                      companyMappings().find(
                        (company) => company.value === dealerId
                      )?.text || 'Select Dealer'
                    }
                    value={dealerId}
                    forceSelection={false}
                    selectOnBlur={false}
                    options={companyMappings()}
                    onChange={(e, data) => {
                      setSaveDealer(true);
                      setCustomerNumber('');
                      setAllSubDealers([]);
                      setDealerId(data.value?.toString());
                    }}
                  />
                  {saveDealer && !purchasePartnersErr && !isUserDealer && (
                    <>
                      {purchasePartners && (
                        <div className="purchase-partner-group">
                          {purchasePartners?.map(
                            (
                              purchasePartner: PurchasePartner,
                              index: number
                            ) => (
                              <Card
                                className="purchase-partner-card"
                                key={index}
                              >
                                <Card.Header>{`${purchasePartner.code}-${purchasePartner.branchCode}`}</Card.Header>
                                <Card.Meta>{purchasePartner.name}</Card.Meta>
                              </Card>
                            )
                          )}
                        </div>
                      )}
                      <div className="dealer-dropdown-btn">
                        <Button
                          basic
                          className="control-right"
                          content="Cancel"
                          onClick={cancelUpdateDealer}
                        />
                        <Button
                          primary
                          className="control-right"
                          content="Assign to Dealer"
                          onClick={updateDealer}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <>
              <br />
              {flags.dealerDistributor && showSubDealersLoading && (
                <div>
                  <Loader
                    active
                    inline
                    as="pre"
                    style={{ fontFamily: 'inherit' }}
                    content={'Checking sub-dealers, please wait...'}
                  />
                  <br />
                  <br />
                </div>
              )}
              {flags.dealerDistributor && hasSubDealer && (
                <>
                  <div className="subDealer field">
                    <label>Sub-dealers</label>
                    <Select
                      placeholder={
                        subDealersMappings().find(
                          (subDealer) =>
                            subDealer.value.toString() === customerNumber
                        )?.text || 'Assign this project to dealer'
                      }
                      options={subDealersMappings()}
                      onChange={(e, data) => {
                        setUpdateSubDealer(true);
                        setCustomerNumber(data.value?.toString());
                      }}
                      value={customerNumber}
                      forceSelection={false}
                      selectOnBlur={false}
                      search
                      data-cy="subDealer-dropdown"
                    />
                    {updateSubDealer && !isUserDealer && (
                      <div className="dealer-dropdown-btn">
                        <Button
                          basic
                          className="control-right"
                          content="Cancel"
                          onClick={cancelUpdateSubDealer}
                        />
                        <Button
                          primary
                          className="control-right"
                          content="Save"
                          disabled={disableSaveBtn}
                          onClick={saveSubDealer}
                        />
                      </div>
                    )}
                  </div>
                  <br />
                </>
              )}
            </>

            <div className="row">
              <FieldGroup
                model={project}
                fields="name address"
                className="col"
                readonly={!editing}
              />
              <FieldGroup
                model={project}
                fields="customer status"
                className="col"
                readonly={!editing}
              />
            </div>
            <FieldGroup
              model={project}
              fields="notes"
              className="col mtop"
              readonly={!editing}
            />

            <div
              className="controls mtop mbottom"
              style={editing ? MountedStyle : UnmountedStyle}
            >
              {editing && (
                <>
                  {!isNew &&
                    hasProjectAccess &&
                    (checkFeatureAuthorization(
                      Features.DeleteProject,
                      user?.role
                    ) ||
                      (user?.role === Role.SALES &&
                        project.data.userId === user?.id)) && (
                      <Button
                        basic
                        color="red"
                        icon="trash"
                        className="control-left"
                        content="Delete Project"
                        onClick={() =>
                          setForm({ loading: false, confirm: CONFIRM.DELETE })
                        }
                      />
                    )}
                  <Button
                    basic
                    className={!editing ? '' : 'control-right'}
                    content="Cancel"
                    onClick={cancelEdit}
                  />
                </>
              )}
              <Button
                primary
                className={!editing ? 'control-right' : ''}
                content={editing ? 'Save Changes' : 'Create Project'}
                onClick={() => onSubmit()}
              />
            </div>
          </Form>

          <Confirm
            size="mini"
            open={!!form.confirm}
            header={form.confirm?.title}
            content={form.confirm?.msg}
            onConfirm={form.confirm?.action}
            confirmButton={form.confirm?.ok || 'OK'}
            onCancel={() => setForm({ loading: false, confirm: null })}
          />
        </Segment>
      </TransitionGroup>

      {!isNew && (
        <>
          <div className="pads-container">
            <ProjectMeasurement
              project={project.data}
              isGeneratingDxf={generatingDxf}
              generateDxf={rerunDXF}
              updateProject={setProject}
            />
            {checkFeatureAuthorization(Features.Orders, user?.role) && (
              <OrderProvider project={project}>
                <Orders
                  updateProject={setProject}
                  fetchProject={fetchProject}
                  project={project.data}
                  generateDxf={() => rerunDXF(project.data)}
                  isProjectValid={project.isValid()}
                  isDistributorDealer={
                    isDistributorDealer && flags.dealerDistributor
                  }
                />
              </OrderProvider>
            )}
          </div>

          <Segment className="pad">
            <div className="project-table">
              <h2>Project Details</h2>
              <ProjectTable project={project.data} />
            </div>
          </Segment>
        </>
      )}
    </>
  );
}
